import React, { useContext } from 'react';
import BetaBadge from '../../features/shared/components/Badges/Beta';
import Style from './style';
import { TitledVerticalFlexLayout } from '../../features/shared/components/VerticalFixedLayout/VerticalFixedLayout';
import { Button, Spin, Tooltip } from 'antd';
import { Table } from '../../componentsV2/Table';
import { accountFavouritesContext } from '../../features/account/account-favourites/account-favourites.context';
import { AccountIcon } from '../../features/shared/components/AccountIcon';
import { RecordMeetingToggle } from '../../componentsV2/RecordMeetingToggle';
import { formatInsightTime, formatInsightTimeWithYear } from '../../utils/dateUtils';
import { FavouritedAccount } from '../../features/account/account-favourites/account-favourites-types';
import { OptionalTooltipWrapper } from '../../componentsV2/OptionalTooltipWrapper';
import { StoryTimeline } from '../../componentsV2/StoryTimeline';
import { InsightListContainer } from '../../features/recap/components/BulletList/styles';
import { Insight } from '../../componentsV2/Insight';
import { mapMessagesList } from '../../API/utils';
import { useToast } from '../../hooks/useToast';
import { coreService } from '../../services/core/core-service';
import { extractError } from '../../utils/api';
import { FavouritedStatus } from './components/FavouritedStatus';
import { colors } from '../../theme/colors';
import { UpgradeToPro } from '../../componentsV2/UpgradeToPro';
import useFeatures from '../../hooks/useFeatures';

const defaultElement = {
  id: 'default',
  name: 'default',
  provider: null,
  timeline: null,
  narrative: null,
  lastMeetings: [],
  nextMeeting: null,
  lastGeneratedAt: null,
  storyIsGenerating: false,
};

export const AccountFavouritesPage: React.FC = () => {
  const { CenteredContent, NextMeeting, NoMeeting, NoWrap, Container, Title } = Style;

  const useStore = useContext(accountFavouritesContext);
  const favourited = useStore();

  const { error } = useToast();

  const [expand, setExpand] = React.useState<null | { id: string; type: 'ai' | 'views' }>(null);

  const features = useFeatures();
  const availableFeature = features.accountInsights;

  const handleExpand = (id: string, type: 'ai' | 'views') => {
    if (expand?.id === id && expand?.type === type) {
      setExpand(null);
      return;
    }
    setExpand({ id, type });
  };

  const regenerate = (id: string) => {
    coreService
      .generateAccountStory(id)
      .then(() => {
        favourited.setRegenerating(id);
      })
      .catch(err => {
        const message = extractError(err);
        error(message);
      });
  };

  // This is only to show something when the feature is not available.
  if (favourited.accounts.length === 0 && !availableFeature)
    favourited.accounts.push(defaultElement);

  const columns = [
    {
      title: 'Account Name',
      dataIndex: 'favourite-account-name',
      key: 'favourite-account-name',
      render(value: any, record: FavouritedAccount) {
        return (
          <div style={{ display: 'flex', gap: '8px', alignItems: 'center', width: '200px' }}>
            <AccountIcon provider={record.provider || ''} />
            <a style={{ color: colors.black }} href={`/accounts/${record.id}`}>
              <NoWrap>{record.name}</NoWrap>
            </a>
          </div>
        );
      },
    },
    {
      title: 'Next Meeting Date',
      dataIndex: 'favourite-next-meeting',
      key: 'favourite-next-meeting',
      render(value: any, record: FavouritedAccount) {
        return (
          <>
            {record.nextMeeting ? (
              <NextMeeting>
                <div style={{ display: 'flex' }}>
                  <NoWrap>
                    <Tooltip title={record.nextMeeting.name} style={{ width: '100%' }}>
                      {record.nextMeeting.name.trim()}
                    </Tooltip>
                  </NoWrap>
                  ... - {formatInsightTime(new Date(record.nextMeeting.date))}
                </div>

                <RecordMeetingToggle
                  id={record?.nextMeeting?.id || ''}
                  action={favourited.setRecord}
                  checked={record.nextMeeting.record.value}
                  disabled={!record.nextMeeting.record.canRecord}
                  title={record.nextMeeting.record.reason}
                />
              </NextMeeting>
            ) : (
              <NoMeeting>No next meeting scheduled</NoMeeting>
            )}
          </>
        );
      },
    },
    {
      title: 'Action Items',
      dataIndex: 'favourite-ai',
      key: 'favourite-ai',
      render(value: any, record: FavouritedAccount) {
        const aiItemsLength = record.lastMeetings.reduce((prev, current) => {
          return prev + Number(current.actionItems.length || 0);
        }, 0);
        const disabled = record.lastMeetings.length === 0 || aiItemsLength === 0;

        return (
          <OptionalTooltipWrapper
            display={disabled}
            value="This account has no action items in the last two meetings."
          >
            <Button
              style={{ width: '200px' }}
              disabled={disabled}
              onClick={() => handleExpand(record.id, 'ai')}
            >{`${
              expand?.id === record.id && expand?.type === 'ai' ? 'Hide' : 'Show'
            } ${aiItemsLength} Action Items`}</Button>
          </OptionalTooltipWrapper>
        );
      },
    },
    {
      title: 'Status',
      dataIndex: 'favourite-status',
      key: 'favourite-status',
      render(value: any, record: FavouritedAccount) {
        return <FavouritedStatus id={record.id} selected={expand} handleExpand={handleExpand} />;
      },
    },
  ];

  return (
    <TitledVerticalFlexLayout
      title={
        <div style={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
          <BetaBadge />
          <>Favorite Accounts</>
        </div>
      }
    >
      <UpgradeToPro plan="startup" display={!availableFeature}>
        {favourited.loading ? (
          <CenteredContent>
            <Spin />
          </CenteredContent>
        ) : favourited.accounts.length === 0 ? (
          <CenteredContent>
            You don't have any favorite accounts. You can mark any customer as favorite on its
            account page.
          </CenteredContent>
        ) : (
          <Container>
            <Table
              columns={columns}
              pagination={false}
              data={favourited.accounts.map(a => {
                return { ...a, key: a.id };
              })}
              props={{
                expandable: {
                  expandedRowKeys: expand ? [expand.id] : [],
                  expandIcon: () => <></>,
                  expandedRowRender: (record: FavouritedAccount) => {
                    if (!expand) return <></>;

                    if (expand.type === 'ai') {
                      return (
                        <div>
                          {record.lastMeetings.map(meeting => (
                            <div style={{ display: 'flex', flexDirection: 'column', gap: '15px' }}>
                              {meeting.actionItems.length > 0 ? (
                                <Title>
                                  🗓️ {meeting.name} -{' '}
                                  {formatInsightTimeWithYear(new Date(meeting.date))}
                                </Title>
                              ) : (
                                <></>
                              )}
                              <InsightListContainer>
                                {meeting.actionItems.map(bullets => {
                                  return (
                                    <Insight
                                      isRecapExcludedFromInsightsEngine={false}
                                      canEdit={true}
                                      canExclude={false}
                                      openInsight={true}
                                      key={bullets.id}
                                      insight={bullets}
                                      text={bullets.text}
                                      color={'#EFF6FF'}
                                      isEditing={false}
                                      isOpen={false}
                                      played={false}
                                      hoverColor={'#eff6ff80'}
                                      speakers={undefined}
                                      editable={false}
                                      smartClipColor={'#EFF6FF'}
                                      ready={true}
                                      isPublic={true}
                                      insightsCount={meeting.actionItems.length}
                                      recapId={meeting.id}
                                      messages={mapMessagesList(
                                        meeting.messages,
                                        meeting.actionItems,
                                      )}
                                      manageInsight={() => {
                                        return;
                                      }}
                                      itemPlayed={() => {
                                        return;
                                      }}
                                      onClick={() => {
                                        return;
                                      }}
                                      onStopEditing={() => {
                                        return;
                                      }}
                                      onDelete={() => {
                                        return;
                                      }}
                                      onEdit={() => {
                                        return;
                                      }}
                                      play={() => {
                                        return;
                                      }}
                                      removeInsight={() => {
                                        return;
                                      }}
                                      addInsight={() => {
                                        return;
                                      }}
                                    />
                                  );
                                })}
                              </InsightListContainer>
                            </div>
                          ))}
                        </div>
                      );
                    } else {
                      return (
                        <div
                          style={{
                            padding: '12px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                          }}
                        >
                          <StoryTimeline
                            style={{ padding: '16px' }}
                            storyNarrative={record.narrative}
                            storyTimeline={record.timeline || []}
                            accountName={record.name}
                            isGenerating={record.storyIsGenerating}
                            lastGeneratedAt={
                              record.lastGeneratedAt ? new Date(record.lastGeneratedAt) : null
                            }
                            onGenerate={() => regenerate(record.id)}
                          />
                        </div>
                      );
                    }
                  },
                },
              }}
            />
          </Container>
        )}
      </UpgradeToPro>
    </TitledVerticalFlexLayout>
  );
};
