import { useEffect, useMemo } from 'react';
import { useParagon } from '../../paragon/hooks/use-paragon';
import { paragon, ConnectUser, SDK_EVENT } from '@useparagon/connect';
import { useQuery } from '@tanstack/react-query';
import { da } from 'date-fns/locale';
import { coreService } from '../../../services/core/core-service';
import { useSession } from '../../../hooks/useSession';

type CrmIntegration = {
  provider: string;
  status: string;
};

export const useCrmIntegration = () => {
  const { isFetching, user } = useParagon();
  const { user: updateUser } = useSession();

  const {
    data,
    isFetching: isFetchingWorkspaceCrmIntegration,
    refetch,
  } = useQuery({
    queryKey: ['workspace-crm-integration', updateUser?.id],
    queryFn: () => {
      return coreService.getWorkspaceCrmIntegration();
    },
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
  });

  const integration: CrmIntegration | null = useMemo(() => {
    if (!user.authenticated) {
      return null;
    }

    const allIntegrations = Object.entries(user.integrations);
    const enabledIntegrations = allIntegrations.filter(
      integration => (integration[1]?.enabled || false) === true,
    );
    const hubspotIntegration = enabledIntegrations.find(
      integration => integration[0] === 'hubspot',
    );
    const salesforceIntegraton = enabledIntegrations.find(
      integration => integration[0] === 'salesforce',
    );

    const crmIntegration = hubspotIntegration || salesforceIntegraton;

    if (!crmIntegration) {
      return null;
    }

    return {
      provider: crmIntegration[0],
      status: crmIntegration[1]?.credentialStatus || 'INVALID',
    };
  }, [user]);

  const isConnectedEnabled = integration === null && user;
  const isDisconnectEnabled = integration !== null && user;

  const disconnect = async () => {
    if (integration) {
      paragon.connect(integration.provider, {});
    }
  };

  const connect = (provider: string) => {
    paragon.connect(provider, {});
  };

  return {
    isFetching: isFetching || isFetchingWorkspaceCrmIntegration,
    integration,
    isConnectedEnabled,
    isDisconnectEnabled,
    disconnect,
    connect,
    workspaceCrmIntegration: data,
    refetchCrmIntegration: refetch,
  };
};
