import React, { useEffect, useState } from 'react';
import { Mentions, TopMentionsType } from '../../../../../../services/types';
import { Meeting } from './components/Meeting';
import { Pagination, Spin } from 'antd';
import { useQuery } from '@tanstack/react-query';
import { coreService } from '../../../../../../services/core/core-service';
import { useDashboardFilters } from '../../../../../../features/shared/dashboard-filters/dashboard-filters.hooks';
import { DateRangeFilterStore } from '../../../../../../features/shared/dashboard-filters/types';
import { filtersToQueryObject } from '../../../../utils';

import Styles from './styles';
const { Container } = Styles;

interface Props {
  accountId: string[];
  sortBy: { id: string; label: string };
  topic: {
    id: string;
    name: string;
  };
}

export const MentionsContainer: React.FC<Props> = ({ sortBy, topic, accountId }) => {
  const [elems, setElems] = useState<TopMentionsType | null>(null);
  const [pageSize, setPageSize] = useState<[number, number]>([1, 10]);

  const { filters } = useDashboardFilters();

  const { data: topMentions, isFetching } = useQuery({
    queryKey: ['topic-modal-topics', filters, pageSize, accountId],
    queryFn: async () => {
      const dateRange = (filters['dateRange'] as DateRangeFilterStore)?.value?.interval;
      if (!dateRange) return null;

      const filtersObject = filtersToQueryObject(filters);

      return await coreService.getTopMentionsByTopicId(
        topic.id,
        dateRange[0]!.toISOString(),
        dateRange[1]!.toISOString(),
        1,
        10,
        sortBy.id,
        {
          ...filtersObject,
          accountIds: filtersObject.accountIds.length > 0 ? filtersObject.accountIds : accountId,
        },
      );
    },
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    refetchOnReconnect: false,
  });

  const handlePageChange = async (page: number, size: number) => {
    setPageSize([page, size]);
  };

  useEffect(() => {
    setElems(topMentions || null);
  }, [topMentions]);

  return (
    <Container>
      {isFetching ? (
        <Spin />
      ) : (
        (elems?.mentions || []).map((m: Mentions) => {
          return (
            <Meeting
              id={m.id}
              insights={m.mentions}
              account={m.account}
              title={m.title}
              scheduledStartDate={new Date(m.scheduledStartDate)}
            />
          );
        })
      )}
      <Pagination
        style={{
          alignSelf: 'flex-end',
        }}
        onChange={handlePageChange}
        hideOnSinglePage
        showSizeChanger={false}
        total={elems?.pagination?.items?.total || 1}
        pageSize={elems?.pagination?.pages?.pageSize || 10}
        current={elems?.pagination?.pages?.currentPage || 1}
      />
    </Container>
  );
};
