import React, { useEffect, useState } from 'react';
import { TopTopicsType } from '../../../../services/types';
import { PIModal } from '../AllTopicsModal';
import { TopicDetailModal } from '../TopicDetailModal';
import { SelectedTopic } from './types';
import { useDashboardFilters } from '../../../../features/shared/dashboard-filters/dashboard-filters.hooks';
import { DateRangeFilterStore } from '../../../../features/shared/dashboard-filters/types';
import { coreService } from '../../../../services/core/core-service';
import { TopTopicsFilters } from './components/TopTopicsFilters';
import { TopTopicsTable } from './components/TopTopicsTable';
import { DashboardFilterPovider } from '../../../../features/shared/dashboard-filters/dashboard-filters.context';
import { filtersToQueryObject } from '../../utils';
import { SortByType } from '../../types';
import Card from '../../../../componentsV2/Card';

import Styles from './styles';
const { Contents } = Styles;

interface TopTopicsSectionSectionProps {
  data: TopTopicsType | null;
  sortBy: SortByType;
  loading?: boolean;
  setSortBy: (sortBy: SortByType) => void;
}

export const TopTopicsSection: React.FC<TopTopicsSectionSectionProps> = ({
  data,
  loading,
  sortBy,
  setSortBy,
}) => {
  const [modalSortBy, modalSetSortBy] = useState({
    id: 'arr',
    label: 'ARR',
  });

  const [values, setValues] = useState(data);
  const [modalValues, modalSetValues] = useState(data);
  const [fetching, setFetching] = useState(loading);
  const [showAllTopics, setShowAllTopics] = useState(false);
  const [showTopics, setShowTopics] = useState<SelectedTopic | null>(null);

  const spinner = fetching || loading;

  const {
    filters,
    getListFilterOptions,
    getDateFilterOptions,
    getAccountFilterOptions,
    getSelectFilterOptions,
    getNumberRangeFilterOptions,
  } = useDashboardFilters();
  const dateRange = (filters['dateRange'] as DateRangeFilterStore)?.value?.interval;

  useEffect(() => {
    setFetching(true);
    coreService
      .getTopTopics(
        dateRange[0]!.toISOString(),
        dateRange[1]!.toISOString(),
        1,
        10,
        sortBy.id,
        filtersToQueryObject(filters),
      )
      .then(d => setValues(d))
      .finally(() => setFetching(false));
  }, [sortBy]);

  useEffect(() => {
    setFetching(true);
    coreService
      .getTopTopics(
        dateRange[0]!.toISOString(),
        dateRange[1]!.toISOString(),
        1,
        10,
        modalSortBy.id,
        filtersToQueryObject(filters),
      )
      .then(d => modalSetValues(d))
      .finally(() => setFetching(false));
  }, [modalSortBy]);

  useEffect(() => {
    setValues(data);
    modalSetValues(data);
  }, [data]);

  return (
    <Card
      title="Top Topics"
      subtitle="Providing deeper insights into client interactions."
      learnMoreLink="https://google.com"
    >
      <Contents>
        <TopTopicsFilters
          sortType={sortBy.label}
          sortBy={setSortBy}
          values={values}
          viewAllTopics={() => setShowAllTopics(true)}
        >
          <TopTopicsTable onTopicClicked={setShowTopics} data={[]} loading={spinner} />
        </TopTopicsFilters>
      </Contents>

      <PIModal open={showAllTopics} onClose={() => setShowAllTopics(false)} label="All Topics">
        <TopTopicsFilters
          sortType={modalSortBy.label}
          sortBy={modalSetSortBy}
          values={modalValues}
          pagination={(modalValues?.pagination?.pages?.total || 0) > 1}
          onPageChange={async (page, size) => {
            return await coreService.getTopTopics(
              dateRange[0]!.toISOString(),
              dateRange[1]!.toISOString(),
              page,
              size,
              sortBy.id,
              filtersToQueryObject(filters),
            );
          }}
        >
          <TopTopicsTable
            loading={spinner}
            onTopicClicked={t => {
              setShowAllTopics(false);
              setShowTopics(t);
            }}
          />
        </TopTopicsFilters>
      </PIModal>

      <DashboardFilterPovider
        filters={{
          dateRange: {
            type: 'dateRange',
            config: {
              value: 'custom',
              interval: getDateFilterOptions('dateRange'),
            },
          },
          account: {
            type: 'account',
            config: {
              selected: getAccountFilterOptions('account'),
            },
          },
          geography: {
            type: 'list',
            componentProps: {
              label: 'Geography',
            },
            config: {
              allowNoValueSet: true,
              options: getListFilterOptions('geography'),
            },
          },
          industry: {
            type: 'list',
            componentProps: {
              label: 'Industry',
            },
            config: {
              allowNoValueSet: true,
              options: getListFilterOptions('industry'),
            },
          },
          revenueSegment: {
            type: 'list',
            componentProps: {
              label: 'Revenue Segment',
            },
            config: {
              allowNoValueSet: true,
              options: getListFilterOptions('revenueSegment'),
            },
          },
          dealStage: {
            type: 'list',
            componentProps: {
              label: 'Deal Stage',
            },
            config: {
              allowNoValueSet: true,
              options: getListFilterOptions('dealStage'),
            },
          },
          lifecycleStage: {
            type: 'list',
            componentProps: {
              label: 'Lifecycle Stage',
            },
            config: {
              allowNoValueSet: true,
              options: getListFilterOptions('lifecycleStage'),
            },
          },
          arr: {
            type: 'numberRange',
            componentProps: {
              label: 'ARR',
            },
            config: {
              interval: getNumberRangeFilterOptions('arr'),
            },
          },
          daysUntilRenewal: {
            type: 'select',
            config: {
              defaultValue: getSelectFilterOptions('select'),
              options: [
                {
                  value: -1,
                  label: 'All Days Until Renewal',
                },
                {
                  value: 30,
                  label: '30 Days Until Renewal',
                },
                {
                  value: 60,
                  label: '60 Days Until Renewal',
                },
                {
                  value: 90,
                  label: '90 Days Until Renewal',
                },
                {
                  value: 120,
                  label: '120 Days Until Renewal',
                },
              ],
            },
            componentProps: {
              label: 'Days Until Renewal',
              style: { width: 200 },
            },
          },
          insightType: {
            type: 'select',
            config: {
              defaultValue: 'none',
              options: [
                {
                  value: 'none',
                  label: 'All Insights Types',
                },
                {
                  value: 'Risks',
                  label: 'Risks',
                  tooltip:
                    'Evaluate potential risks that might impact customer satisfaction or threaten continuity and renewal, such as operational challenges or dips in user engagement.',
                },
                {
                  value: 'Product Feedback',
                  label: 'Product Feedback',
                  tooltip:
                    'Gather insights on how customers perceive the product, pinpointing areas needing improvement.',
                },
                {
                  value: 'Advocacy',
                  label: 'Advocacy',
                  tooltip:
                    'Measure customer endorsement and support to understand their level of engagement and loyalty.',
                },
                {
                  value: 'Growth Opportunities',
                  label: 'Growth Opportunities',
                  tooltip:
                    'Identify potential areas for account expansion to enhance account value and explore new opportunities.',
                },
              ],
            },
            componentProps: {
              label: 'Insight Type',
              style: { width: 200 },
            },
          },
          meetingType: {
            type: 'meetingType',
            config: {
              defaultValue: null,
            },
          },
        }}
      >
        <TopicDetailModal
          open={!!showTopics}
          onClose={() => setShowTopics(null)}
          topic={{
            id: showTopics?.topicId || '',
            name: showTopics?.topicName || '',
          }}
        />
      </DashboardFilterPovider>
    </Card>
  );
};
