import { Switch, Tooltip } from 'antd';
import React, { PropsWithChildren } from 'react';
import {
  Container,
  Content,
  Description,
  ExtraDescription,
  LearnMore,
  RecommendedSetting,
  RecommendedSettingHref,
  ToggleContainer,
} from './style';
import { OptionalTooltipWrapper } from '../../../../../componentsV2/OptionalTooltipWrapper';
interface Props {
  checked?: boolean;
  description?: string | React.ReactNode;
  extraDescription?: string;
  recommendedSetting?: string;
  onClickRecommendedSetting?: () => void;
  onChange?: (e: Boolean) => any;
  learnMore?: string;
  disabled?: boolean;
  tooltipTitle?: string | React.ReactNode;
  style?: React.CSSProperties;
}

const ToggleCard: React.FC<PropsWithChildren<Props>> = ({
  description,
  extraDescription,
  checked,
  recommendedSetting,
  onChange,
  learnMore,
  onClickRecommendedSetting,
  disabled = false,
  tooltipTitle,
  style,
  children,
}) => {
  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: '14px', width: '100%' }}>
      <Container style={style}>
        <Content>
          <Description>{description}</Description>
          <ExtraDescription>
            {extraDescription}
            {learnMore && (
              <LearnMore href={learnMore} target="_blank">
                Learn More
              </LearnMore>
            )}
          </ExtraDescription>

          {recommendedSetting && (
            <RecommendedSetting>
              Recommended setting:
              {onClickRecommendedSetting ? (
                <RecommendedSettingHref onClick={onClickRecommendedSetting}>
                  {recommendedSetting}
                </RecommendedSettingHref>
              ) : (
                <div style={{ color: '#6A6A6A', fontWeight: '600', paddingLeft: '4px' }}>
                  {recommendedSetting}
                </div>
              )}
            </RecommendedSetting>
          )}
        </Content>

        <ToggleContainer>
          <OptionalTooltipWrapper display={disabled} value={tooltipTitle}>
            <Switch checked={checked} onChange={onChange} disabled={disabled} />
          </OptionalTooltipWrapper>
        </ToggleContainer>
      </Container>

      <div style={{ padding: '0px 16px' }}>{children}</div>
    </div>
  );
};

export default ToggleCard;
