import { paragon, ConnectUser, AuthenticatedConnectUser, SDK_EVENT } from '@useparagon/connect';
import { useEffect, useState } from 'react';
import { coreService } from '../../../services/core/core-service';
import { useQuery } from '@tanstack/react-query';
import { useSession } from '../../../hooks/useSession';

export const useParagon = () => {
  const { user } = useSession();
  const [userData, setUserData] = useState<ConnectUser>({ authenticated: false });
  const [isAuthenticating, setIsAuthenticating] = useState(true);

  const { data, isFetching, refetch } = useQuery({
    queryKey: ['paragon-token', user?.id],
    queryFn: () => {
      return coreService.getWorkspaceParagonToken();
    },
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    refetchOnReconnect: false,
  });

  useEffect(() => {
    paragon.subscribe(SDK_EVENT.ON_INTEGRATION_UNINSTALL, () => {
      refetch();
    });

    paragon.subscribe(SDK_EVENT.ON_INTEGRATION_INSTALL, () => {
      refetch();
    });
  }, [refetch]);

  useEffect(() => {
    if (data?.paragon) {
      paragon
        .authenticate(data.paragon.projectId, data.paragon.token)
        .then(async () => {
          const user = paragon.getUser();
          setUserData(user);
        })
        .catch(() => {
          setUserData({ authenticated: false });
        })
        .finally(() => {
          setIsAuthenticating(false);
        });
    }
  }, [data]);

  return {
    isFetching: isFetching || isAuthenticating,
    user: userData,
  };
};
