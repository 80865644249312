import React, { ReactElement } from 'react';
import Styles from './styles';
import { Tooltip } from 'antd';

const { NotMappedText, CrmPropertyValue } = Styles;

interface Props {
  crmPropertyValue: string | null | ReactElement;
  onMouseEnter?: React.MouseEventHandler<HTMLSpanElement>;
  onMouseLeave?: React.MouseEventHandler<HTMLSpanElement>;
}

export const PropertyValue: React.FC<Props> = ({
  crmPropertyValue,
  onMouseEnter,
  onMouseLeave,
}) => {
  if (!crmPropertyValue) {
    return (
      <Tooltip title="Requires CRM Setup to display the value. ">
        <NotMappedText
          onClick={() => window.open('/settings?tab=my-organization#CRM-SETUP-ID', '_blank')}
        >
          <div>Property Not Mapped</div>
        </NotMappedText>
      </Tooltip>
    );
  }

  return (
    <CrmPropertyValue onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
      {crmPropertyValue}
    </CrmPropertyValue>
  );
};
