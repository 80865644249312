import { useState } from 'react';
import { Table } from '../../../../../../componentsV2/Table';
import { AccountLabel } from '../../../../../../features/shared/components/AccountLabel';
import { format } from 'date-fns';
import { PlusCircleOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { MentionsContainer } from '../MentionsContainer';

export type TopAccountsItemType = {
  id: string;
  name: string;
  mentions: number;
};

export interface TopAccountRecord {
  account: {
    id: string;
    name: string;
    provider: string;
  };
  arr: number | null;
  renewalDate: Date | null;
}

export interface Props {
  sortBy: { id: string; label: string };
  topic: {
    id: string;
    name: string;
  };
  elems?: TopAccountRecord[];
  loading?: boolean;
}

export const TopAccountsCondensedTable: React.FC<Props> = ({
  elems: data,
  topic,
  sortBy,
  loading = false,
}) => {
  const [expandRow, setExpandRow] = useState<string[]>([]);

  return (
    <Table
      pagination={false}
      loading={loading}
      columns={[
        {
          title: 'Account',
          key: 'accounts',
          render: value => {
            return <AccountLabel account={value} />;
          },
        },
        {
          title: 'ARR',
          key: 'arr',
          dataIndex: 'arr',
          render: value => `$ ${value?.arr || 0}`,
        },
        {
          title: 'Renewal',
          key: 'renewal',
          dataIndex: 'renewalDate',
          render: value => {
            if (!value) return `-`;

            const differenceInDays = (value.getTime() - new Date().getTime()) / 1000 / 3600 / 24;
            if (differenceInDays < 0) {
              return `Today`;
            }

            if (differenceInDays <= 120) {
              return `In ${Math.floor(differenceInDays)} days`;
            }

            return format(value, 'yyyy-MM-dd');
          },
        },
        {
          title: '# of Mentions',
          key: 'mentions',
          dataIndex: 'mentions',
          render: value => `${value}`,
        },
        {
          title: '# of Meetings',
          key: 'meetings',
          dataIndex: 'meetings',
          render: value => `${value}`,
        },
      ]}
      data={data || []}
      props={{
        expandable: {
          expandedRowKeys: expandRow,
          expandIcon: (row: any) => (
            <Button
              icon={<PlusCircleOutlined />}
              onClick={() => {
                if (expandRow.includes(row.record.id)) {
                  setExpandRow(expandRow.filter(id => id !== row.record.id));
                } else {
                  setExpandRow([...expandRow, row.record.id]);
                }
              }}
            />
          ),
          expandedRowRender: (record: any) => {
            return (
              <MentionsContainer
                sortBy={sortBy}
                topic={topic}
                accountId={[record.id, record.name]}
              />
            );
          },
        },
      }}
    />
  );
};
