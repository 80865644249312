import { useEffect, useState } from 'react';
import { coreService } from '../../../../../services/core/core-service';
import { useDashboardFilters } from '../../../../../features/shared/dashboard-filters/dashboard-filters.hooks';
import { DateRangeFilterStore } from '../../../../../features/shared/dashboard-filters/types';
import { filtersToQueryObject } from '../../../utils';
import { TopTopicsFilters } from '../../TopTopics/components/TopTopicsFilters';
import { TopTopicsTable } from '../../TopTopics/components/TopTopicsTable';
import { TopTopicsType } from '../../../../../services/types';
import { DashboardFilterPovider } from '../../../../../features/shared/dashboard-filters/dashboard-filters.context';
import { TopicDetailModal } from '../../TopicDetailModal';
import { PIModal } from '../../AllTopicsModal';
import { SelectedTopic } from '../../TopTopics/types';
import { SortByType } from '../../../types';

import Styles from '../styles';
const { Contents } = Styles;

type TopicValues = 'Risks' | 'Product Feedback' | 'Advocacy' | 'Growth Opportunities';

interface Props {
  insightType: TopicValues;
  sortBy: SortByType;
  loading?: boolean;
  setSortBy: (sortBy: SortByType) => void;
}

export const TopicType: React.FC<Props> = ({ loading, insightType, sortBy, setSortBy }) => {
  const [modalSortBy, modalSetSortBy] = useState({
    id: 'mentions',
    label: '# of Mentions',
  });

  const [fetching, setFetching] = useState(loading);
  const [values, setValues] = useState<TopTopicsType | null>(null);
  const [modalValues, modalSetValues] = useState<TopTopicsType | null>(null);
  const [showAllTopics, setShowAllTopics] = useState(false);
  const [showTopics, setShowTopics] = useState<SelectedTopic | null>(null);

  const spinner = fetching || loading;

  const {
    filters,
    getListFilterOptions,
    getDateFilterOptions,
    getAccountFilterOptions,
    getSelectFilterOptions,
    getNumberRangeFilterOptions,
  } = useDashboardFilters();
  const dateRange = (filters['dateRange'] as DateRangeFilterStore)?.value?.interval;

  useEffect(() => {
    setFetching(true);
    coreService
      .getTopTopics(
        dateRange[0]!.toISOString(),
        dateRange[1]!.toISOString(),
        1,
        10,
        modalSortBy.id,
        filtersToQueryObject(filters),
      )
      .then(data => {
        modalSetValues(data);
      })
      .finally(() => setFetching(false));
  }, [modalSortBy]);

  useEffect(() => {
    setFetching(true);
    coreService
      .getTopTopics(
        dateRange[0]!.toISOString(),
        dateRange[1]!.toISOString(),
        1,
        10,
        sortBy.id,
        filtersToQueryObject(filters),
      )
      .then(data => {
        setValues(data);
        modalSetValues(data);
      })
      .finally(() => setFetching(false));
  }, [sortBy, filters]);

  useEffect(() => {
    setFetching(true);
    coreService
      .getTopTopics(
        dateRange[0]!.toISOString(),
        dateRange[1]!.toISOString(),
        1,
        10,
        sortBy.id,
        filtersToQueryObject(filters),
      )
      .then(data => {
        setValues(data);
        modalSetValues(data);
      })
      .finally(() => setFetching(false));
  }, []);

  return (
    <>
      <Contents>
        <TopTopicsFilters
          label={insightType}
          values={values}
          viewAllTopics={() => setShowAllTopics(true)}
          sortType={sortBy.label}
          sortBy={v => {
            setSortBy(v);
            modalSetSortBy(v);
          }}
        >
          <TopTopicsTable onTopicClicked={t => setShowTopics(t)} loading={spinner} />
        </TopTopicsFilters>
      </Contents>

      <DashboardFilterPovider
        filters={{
          dateRange: {
            type: 'dateRange',
            config: {
              value: 'custom',
              interval: getDateFilterOptions('dateRange'),
            },
          },
          account: {
            type: 'account',
            config: {
              selected: getAccountFilterOptions('account'),
            },
          },
          geography: {
            type: 'list',
            componentProps: {
              label: 'Geography',
            },
            config: {
              allowNoValueSet: true,
              options: getListFilterOptions('geography'),
            },
          },
          industry: {
            type: 'list',
            componentProps: {
              label: 'Industry',
            },
            config: {
              allowNoValueSet: true,
              options: getListFilterOptions('industry'),
            },
          },
          revenueSegment: {
            type: 'list',
            componentProps: {
              label: 'Revenue Segment',
            },
            config: {
              allowNoValueSet: true,
              options: getListFilterOptions('revenueSegment'),
            },
          },
          dealStage: {
            type: 'list',
            componentProps: {
              label: 'Deal Stage',
            },
            config: {
              allowNoValueSet: true,
              options: getListFilterOptions('dealStage'),
            },
          },
          lifecycleStage: {
            type: 'list',
            componentProps: {
              label: 'Lifecycle Stage',
            },
            config: {
              allowNoValueSet: true,
              options: getListFilterOptions('lifecycleStage'),
            },
          },
          arr: {
            type: 'numberRange',
            componentProps: {
              label: 'ARR',
            },
            config: {
              interval: getNumberRangeFilterOptions('arr'),
            },
          },
          daysUntilRenewal: {
            type: 'select',
            config: {
              defaultValue: getSelectFilterOptions('select'),
              options: [
                {
                  value: -1,
                  label: 'All Days Until Renewal',
                },
                {
                  value: 30,
                  label: '30 Days Until Renewal',
                },
                {
                  value: 60,
                  label: '60 Days Until Renewal',
                },
                {
                  value: 90,
                  label: '90 Days Until Renewal',
                },
                {
                  value: 120,
                  label: '120 Days Until Renewal',
                },
              ],
            },
            componentProps: {
              label: 'Days Until Renewal',
              style: { width: 200 },
            },
          },
          insightType: {
            type: 'select',
            config: {
              selected: insightType,
              options: [
                {
                  value: 'none',
                  label: 'All Insights Types',
                },
                {
                  value: 'Risks',
                  label: 'Risks',
                  tooltip:
                    'Evaluate potential risks that might impact customer satisfaction or threaten continuity and renewal, such as operational challenges or dips in user engagement.',
                },
                {
                  value: 'Product Feedback',
                  label: 'Product Feedback',
                  tooltip:
                    'Gather insights on how customers perceive the product, pinpointing areas needing improvement.',
                },
                {
                  value: 'Advocacy',
                  label: 'Advocacy',
                  tooltip:
                    'Measure customer endorsement and support to understand their level of engagement and loyalty.',
                },
                {
                  value: 'Growth Opportunities',
                  label: 'Growth Opportunities',
                  tooltip:
                    'Identify potential areas for account expansion to enhance account value and explore new opportunities.',
                },
              ],
            },
            componentProps: {
              label: 'Insight Type',
              style: { width: 200 },
            },
          },
          meetingType: {
            type: 'meetingType',
            config: {
              defaultValue: null,
            },
          },
        }}
      >
        <TopicDetailModal
          insightType={insightType}
          open={!!showTopics}
          onClose={() => setShowTopics(null)}
          topic={{
            id: showTopics?.topicId || '',
            name: showTopics?.topicName || '',
          }}
        />
      </DashboardFilterPovider>

      <PIModal
        open={showAllTopics}
        onClose={() => setShowAllTopics(false)}
        label={`All ${insightType}`}
      >
        <TopTopicsFilters
          values={modalValues}
          pagination={(modalValues?.pagination?.pages?.total || 0) > 1}
          onPageChange={async (page, size) => {
            return await coreService.getTopTopics(
              dateRange[0]!.toISOString(),
              dateRange[1]!.toISOString(),
              page,
              size,
              modalSortBy.id,
              { insightType: insightType },
            );
          }}
          sortType={modalSortBy.label}
          sortBy={modalSetSortBy}
        >
          <TopTopicsTable
            loading={spinner}
            onTopicClicked={t => {
              setShowAllTopics(false);
              setShowTopics(t);
            }}
          />
        </TopTopicsFilters>
      </PIModal>
    </>
  );
};
