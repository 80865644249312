import React, { useEffect, useState } from 'react';
import { TitledVerticalFlexLayout } from '../../features/shared/components/VerticalFixedLayout/VerticalFixedLayout';
import { useLocationChange } from '../../features/analytics/hooks/useLocationChange';
import { Button, Space } from 'antd';
import { Alert } from '../../componentsV2/Alert/Alert';
import { ApiOutlined, DatabaseOutlined } from '@ant-design/icons';
import { useIntegrations } from '../../features/shared/hooks/useIntegrations';
import { UpgradeToPro } from '../../componentsV2/UpgradeToPro';
import { useDashboardFilters } from '../../features/shared/dashboard-filters/dashboard-filters.hooks';
import { TopAccountsSection } from './components/TopAccounts';
import { TopTopicsByTypeSection } from './components/TopTopicByType';
import { AllInsightQueryData } from '../../services/types';
import { TopTopicsSection } from './components/TopTopics';
import { coreService } from '../../services/core/core-service';
import { filtersToQueryObject } from './utils';
import { InsightsAskMeAnything } from '../insights/components/InsightsAskMeAnything';
import { KeyToFilterStore } from '../../features/shared/dashboard-filters/types';
import StoredFilters from '../insights/components/StoredFilters';
import useFeatures from '../../hooks/useFeatures';
import BetaBadge from '../../features/shared/components/Badges/Beta';

import Styles from './styles';
const { Contents, FiltersGroup, Header, HeaderRow } = Styles;

interface Props {
  initialData: AllInsightQueryData | null;
  onStoreFilter(f: Partial<KeyToFilterStore>): void;
}

export const PortfolioInsightsPage: React.FC<Props> = ({ initialData }) => {
  useLocationChange();

  const [showFilters, setShowFilters] = useState(true);
  const [data, setData] = useState<AllInsightQueryData | null>(initialData);
  const [loading, setLoading] = useState(true);
  const [accountsSortBy, setAccountsSortBy] = useState({
    id: 'mentions',
    label: '# of Mentions',
  });
  const [topicsSortBy, setTopicsSortBy] = useState({
    id: 'mentions',
    label: '# of Mentions',
  });

  const { hasCrmIntegration } = useIntegrations();
  const { render, filters } = useDashboardFilters();

  const features = useFeatures();
  const availableFeature = features.portfolioInsights;

  const handleConnectCrm = () => {
    window.open('/settings?tab=integrations', '_blank');
  };

  useEffect(() => {
    setLoading(true);
    coreService
      .getAllInsightsEngine(
        (filters.dateRange.value as any)?.interval[0].toISOString(),
        (filters.dateRange.value as any)?.interval[1].toISOString(),
        accountsSortBy.id,
        topicsSortBy.id,
        filtersToQueryObject(filters),
      )
      .then(data => setData(data))
      .catch(e => console.error(e))
      .finally(() => setLoading(false));
  }, [filters]);

  useEffect(() => {
    setData(initialData);
    if (initialData !== null) setLoading(false);
  }, [initialData]);

  return (
    <TitledVerticalFlexLayout
      sider={true}
      title={
        <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
          Portfolio Insights <BetaBadge />
        </div>
      }
      titleDescription={
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <div style={{ maxWidth: '600px' }}>
            This tool lets you analyze data exclusively from your external meetings, providing
            deeper insights into client interactions.
          </div>

          <StoredFilters
            showHideFilter={() => setShowFilters(!showFilters)}
            disabled={!availableFeature}
            label={(showFilters ? 'Hide' : 'Show') + ' Filters'}
          />
        </div>
      }
    >
      <UpgradeToPro plan="business" display={!availableFeature}>
        <Contents>
          <Header>
            <HeaderRow visible={showFilters}>
              <FiltersGroup>
                {render('dateRange')}
                {render('account')}
                {render('industry')}
                {render('lifecycleStage')}
                {render('arr')}
                {render('geography')}
                {render('revenueSegment')}
                {render('dealStage')}
                {render('daysUntilRenewal')}
                {render('meetingType')}

                {!hasCrmIntegration && (
                  <Button type="link" onClick={handleConnectCrm}>
                    <Space>
                      Connect your CRM <ApiOutlined />
                    </Space>
                  </Button>
                )}

                <StoredFilters.Actions />
              </FiltersGroup>
            </HeaderRow>
          </Header>

          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <InsightsAskMeAnything category="Risks" />

            <Alert
              customIcon={<DatabaseOutlined style={{ color: '#21A6FF' }} />}
              message={`Insights come from ${
                data?.topAccounts?.pagination?.items?.total || '-'
              } meeting recaps based on your applied filters.`}
              variant="info"
              title=""
            />
          </div>

          <TopAccountsSection
            sortBy={accountsSortBy}
            setSortBy={setAccountsSortBy}
            data={data?.topAccounts || null}
            loading={loading}
          />

          <TopTopicsSection
            sortBy={topicsSortBy}
            setSortBy={setTopicsSortBy}
            data={data?.topTopics || null}
            loading={loading}
          />

          <TopTopicsByTypeSection loading={loading} filterOptions={data?.filters || null} />
        </Contents>
      </UpgradeToPro>
    </TitledVerticalFlexLayout>
  );
};
