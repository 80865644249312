import React, { useMemo, useState } from 'react';
import { differenceInDays } from 'date-fns';
import { Flex, Tooltip } from 'antd';
import { PropertyValue } from './components/PropertyValue';
import { useAccountOverview } from '../../../../features/account/account-overview/account-overview.hook';
import Styles from './style';
import { useQuery } from '@tanstack/react-query';
import { coreService } from '../../../../services/core/core-service';
import { ArrowLeftOutlined, ArrowRightOutlined } from '@ant-design/icons';
import { OptionalTooltipWrapper } from '../../../../componentsV2/OptionalTooltipWrapper';
// import { Mapping } from '../../../../features/crm/property-mapping/types';
// import { propertyMappingContext } from '../../../../features/crm/property-mapping/property-mapping.context';
// import { UpdateLoader } from '../../../../componentsV2/UpdateLoader';

const { Section, SectionTitle2, SectionHeader } = Styles;

const propertyToNumber = (value: string) => {
  const possibleDate = new Date(value);

  if (possibleDate.toString() !== 'Invalid Date') {
    return possibleDate.toLocaleString();
  }

  const isNum = Number(value);
  if (!isNaN(isNum)) {
    return isNum.toLocaleString();
  }

  return value;
};

/*
const getCrmPropertyValue = (
  mapping: Mapping[],
  propertyId: string,
  accountProperty?: string | number,
  defaultMessage?: string,
  formattingFn: (value: any) => string = value => value,
) => {
  const property = mapping.find(m => m.property.id === propertyId);

  if (!property) {
    return null;
  }

  if (!property.crmProperty) {
    return null;
  }

  if (accountProperty) {
    return formattingFn(accountProperty);
  }

  return defaultMessage || 'No value provided';
};
*/

export const Overview: React.FC = () => {
  const { selectedAccount: account } = useAccountOverview();
  const [currentIndex, setCurrentIndex] = useState(0);

  const { data, isFetching } = useQuery({
    queryKey: ['pull-properties', account.id, account.mappingId],
    queryFn: async () => {
      if (account.provider === 'email') {
        return null;
      }

      const accountDetails = await coreService
        .getCrmAccountProperties(account.mappingId)
        .then(res => res.properties)
        .catch(() => []);
      return accountDetails;
    },
    refetchOnMount: false,
    refetchOnWindowFocus: false,
  });

  /*
  const { mapping } = store();
  const [isEditing, setIsEditing] = useState(false);
  */

  const daysEngaged = (date?: Date) => {
    const difference = differenceInDays(new Date(), new Date(date || ''));
    if (isNaN(difference)) {
      return '-';
    } else {
      return `${difference} ${difference > 1 || difference === 0 ? 'days' : 'day'} ago`;
    }
  };

  const resolveLastMeetingLabel = (
    lastMeetingInformation?: null | {
      date: Date;
      users: { id: string; email: string; fullName: string }[];
    },
  ) => {
    if (!lastMeetingInformation || !lastMeetingInformation.users.length) return <span>-</span>;

    const { date, users } = lastMeetingInformation;
    const formatedDate = daysEngaged(new Date(date || ''));
    const usersLabel = users.length > 1 ? 'Multiple' : users[0].email;
    const participantsEmails = (
      <>
        <strong>{'Meeting participants\n'}</strong>
        {users.map(user => user.email).join('\n')}
      </>
    );

    if (users.length > 1) {
      return (
        <span>
          {' '}
          {formatedDate} <Tooltip title={participantsEmails}>{`(${usersLabel})`}</Tooltip>
        </span>
      );
    } else {
      return <span>{`${formatedDate} (${usersLabel})`}</span>;
    }
  };

  /*
  const handlePrimaryDealChanged = (primaryDealId: string) => {
    const deal = account.deals.find(deal => deal.id === primaryDealId);
    if (deal) {
      primaryDealChanged(deal);
    }
  };
  */

  const propertiesToDisplay = useMemo(() => {
    if (!data) {
      return [];
    }

    return data.slice(currentIndex, currentIndex + 4);
  }, [data, currentIndex]);

  const showPrevious = currentIndex > 0;

  const showNext = currentIndex + 4 < (data?.length || 0);

  return (
    <div style={{ display: 'flex', gap: '10px' }}>
      <Section style={{ gridTemplateColumns: 'auto', width: '300px' }}>
        <SectionTitle2>Meetings</SectionTitle2>
        <SectionHeader>Last Meeting</SectionHeader>
        <PropertyValue crmPropertyValue={resolveLastMeetingLabel(account.lastMeetingDateAll)} />
      </Section>

      {account.provider !== 'email' ? (
        <Section style={{ width: '600px' }}>
          <SectionTitle2>CRM Properties</SectionTitle2>
          <Flex vertical gap={8}>
            {isFetching ? (
              <PropertyValue crmPropertyValue={'Loading properties...'} />
            ) : propertiesToDisplay.length === 0 ? (
              <PropertyValue crmPropertyValue={'No properties'} />
            ) : (
              <Flex gap={16}>
                {showPrevious && (
                  <ArrowLeftOutlined
                    style={{ color: '#ff6b54' }}
                    onClick={() => setCurrentIndex(ind => ind - 4)}
                  />
                )}
                {propertiesToDisplay.map(p => {
                  const value = propertyToNumber(p.value || 'No value');
                  return (
                    <Flex vertical gap={4}>
                      <SectionHeader>{p.label}</SectionHeader>
                      <OptionalTooltipWrapper value={value} display={Boolean(value)}>
                        <PropertyValue crmPropertyValue={value} />
                      </OptionalTooltipWrapper>
                    </Flex>
                  );
                  return;
                })}
                {showNext && (
                  <ArrowRightOutlined
                    style={{ color: '#ff6b54' }}
                    onClick={() => setCurrentIndex(ind => ind + 4)}
                  />
                )}
              </Flex>
            )}
          </Flex>
        </Section>
      ) : (
        <></>
      )}
    </div>
  );
};
