import { Table as AntTable, Tooltip } from 'antd';
import { ReactNode, useMemo } from 'react';
import { ColumnsType, TablePaginationConfig } from 'antd/es/table';
import { InfoCircleOutlined } from '@ant-design/icons';

import Styles from './styles';
const { Table: StyledTable, TableHeader, TableHead, ColumnName, CellLabel } = Styles;

export type TableColumnDefinition<T> = {
  dataIndex?: string;
  width?: string;
  title?: string;
  tooltip?: string;
  key: string;
  onCell?: (data: T, index?: number) => React.HTMLAttributes<any> & React.TdHTMLAttributes<any>;
  render?: (value: any, record: T, index: number) => ReactNode;
  sorter?: (a: T, b: T) => number;
};

interface Props<T extends object> {
  data: T[];
  loading?: boolean;
  pagination?: false | TablePaginationConfig;
  rowSelection?: any;
  columns: TableColumnDefinition<T>[];
  props?: any;
}

export function Table<T extends object>(props: Props<T>) {
  const columns: ColumnsType<T> = useMemo(() => {
    return props.columns.map(column => {
      return {
        title: column.title ? (
          <div style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
            <ColumnName>{column.title}</ColumnName>
            {!!column.tooltip && (
              <Tooltip title={column.tooltip} placement="bottomLeft">
                <InfoCircleOutlined />
              </Tooltip>
            )}
          </div>
        ) : null,
        width: column.width,
        dataIndex: column.dataIndex,
        render: (value, record, index) => {
          if (column.render) return column.render(value, record, index);
          return <CellLabel width={column.width}>{value}</CellLabel>;
        },
        showSorterTooltip: column.sorter ? { target: 'full-header' } : false,
        sorter: column.sorter,
      };
    });
  }, [props.columns]);

  const otherProps = props.props || {};

  return (
    <AntTable
      dataSource={props.data}
      loading={props.loading}
      columns={columns}
      pagination={props.pagination}
      rowSelection={props.rowSelection}
      components={{
        table: StyledTable,
        header: {
          wrapper: TableHead,
          cell: TableHeader,
        },
      }}
      {...otherProps}
    />
  );
}
