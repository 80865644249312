import React, { useState } from 'react';
import Styled from './style';
import { Modal } from '../../../../../../../../componentsV2/Modal';
import { Radio } from 'antd';

const { Content, Description } = Styled;

export interface Props {
  isLoading: boolean;
  onConfirm: (selectedCRM: string) => unknown;
  onCancel: () => unknown;
  onClose: () => unknown;
  isOpen: boolean;
}

export const ConnectCRMModal: React.FC<Props> = ({
  isLoading,
  onConfirm,
  onCancel,
  onClose,
  isOpen,
}) => {
  const [selectedCRM, setSelectedCRM] = useState('HUBSPOT');

  return (
    <Modal
      title="Connect CRM"
      width={602}
      primaryAction={{
        label: 'Connect CRM',
        loading: isLoading,
        onClick: () => onConfirm(selectedCRM),
      }}
      secondaryAction={{ label: 'Cancel', onClick: onCancel }}
      onClose={onClose}
      open={isOpen}
      helpArticle={{
        label: 'More about CRM integration',
        url: 'https://help.update.ai/en/articles/9056845-how-do-i-make-sure-every-meeting-in-my-workspace-is-pushed-to-our-crm',
      }}
    >
      <Content>
        <Description>
          You are about to connect your CRM. The CRM integration is used as a source of truth for
          matching accounts and syncing CRM Property fields.
        </Description>

        <div>
          <Radio.Group
            defaultValue={selectedCRM}
            buttonStyle="solid"
            onChange={e => setSelectedCRM(e.target.value)}
          >
            <Radio.Button value="HUBSPOT">Hubspot</Radio.Button>
            <Radio.Button value="SALESFORCE">Salesforce</Radio.Button>
          </Radio.Group>
        </div>
      </Content>
    </Modal>
  );
};
