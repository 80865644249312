import React, { useState } from 'react';
import { Button, Divider, Tooltip } from 'antd';
import {
  ChangePasswordButtonContainer,
  Buttons,
  Container,
  DeleteButton,
  ErrorMessage,
  FormContainer,
  Row,
  StyledInput,
} from './style';
import Section from '../_Misc/Section';
import DeleteModal from '../Organizations/components/DeleteModal';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { coreAPI } from '../../../../API/core';
import { useChangePassword } from '../../../../hooks/useChangePassword';
import { logoutUser } from '../../../../redux/slices/sessionSlice';
import { useToast } from '../../../../hooks/useToast';
import { TimeZone } from './TimeZone';

interface Props {
  firstName: string;
  lastName: string;
  email?: string;
  role?: string;
  disableChangePassword?: boolean;
}

const Account: React.FC<Props> = ({ firstName, lastName, email, disableChangePassword }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [showChangePasswordForm, setShowChangePasswordForm] = useState(false);
  const [shouldShowModal, setShouldShowModal] = useState(false);
  const [shouldShowPassword, setShouldShowPassword] = useState(false);

  const { error } = useToast();

  const {
    firstNameValue,
    lastNameValue,
    status,
    errorMessage,
    newPassword,
    confirmPassword,
    handleChangeConfirmPassword,
    handleChangeFirstName,
    handleChangeLastName,
    handleChangePassword,
    resetFields,
    handleSaveUser,
    handleSavePassword,
  } = useChangePassword(firstName, lastName);

  const formHasNotChanged =
    firstName === firstNameValue &&
    lastName === lastNameValue &&
    confirmPassword.length === newPassword.length &&
    confirmPassword.length === 0 &&
    newPassword.length === 0;

  const handleChangePasswordButton = () => {
    setShowChangePasswordForm(!showChangePasswordForm);
  };

  const handleCancel = () => {
    resetFields();
    setShowChangePasswordForm(false);
  };

  const handleSave = () => {
    if (showChangePasswordForm) {
      handleSavePassword();
      handleSaveUser();
    } else {
      handleSaveUser();
    }
    setShowChangePasswordForm(false);
  };

  const logout = () => {
    dispatch(logoutUser());
    navigate('/');
  };

  const handleDeleteUser = async () => {
    const api = new coreAPI();
    try {
      await api.deleteUser();
      logout();
    } catch (e) {
      error((e as Error).message);
    }
  };

  return (
    <Container>
      <DeleteModal
        shouldShow={shouldShowModal}
        closeModal={() => setShouldShowModal(false)}
        heading="Delete your account"
        detail={
          'Are you sure you want to delete your account and all associated data?\n\nThis action cannot be undone.'
        }
        confirmButtonText="Yes, I'm sure."
        onConfirm={handleDeleteUser}
      />
      <Section
        title="Account information"
        description="Unwrap the gift of your account details right here!"
      >
        {/* <LogosContainer>
          <UserInfo>
            <Label>Photo</Label>
            <UploadContainer>
              <Avatar src="" size={64}/>
              <ChangeAvatarButton>Change</ChangeAvatarButton>
            </UploadContainer>
          </UserInfo>
          <CompanyLogoContainer>
            <OrganizationRoleLabel>
              Organization | Role: <RoleBadge>{role ? role.toLowerCase() : ''}</RoleBadge>
            </OrganizationRoleLabel>
            <CompanyLogo></CompanyLogo>
          </CompanyLogoContainer>
        </LogosContainer> */}
        <FormContainer>
          <Row>
            <StyledInput
              label="First name"
              inputValue={firstNameValue}
              onChange={handleChangeFirstName}
              id="firstNameInput"
            />
            <StyledInput
              label="Last name"
              inputValue={lastNameValue}
              onChange={handleChangeLastName}
              id="lastNameInput"
            />
            <StyledInput label="Email address" disabled inputValue={email} id="emailInput" />
          </Row>

          <Row>
            <StyledInput
              label="Password"
              disabled
              inputValue={'••••••••••••'}
              id="currentPasswordInput"
            />

            <ChangePasswordButtonContainer>
              {!disableChangePassword ? (
                <Button style={{ height: '38px' }} onClick={handleChangePasswordButton}>
                  Change
                </Button>
              ) : (
                <Tooltip title="You are not allowed to change your password since you signed in with SSO">
                  <Button style={{ height: '38px' }} onClick={handleChangePasswordButton} disabled>
                    Change
                  </Button>
                </Tooltip>
              )}
            </ChangePasswordButtonContainer>
          </Row>

          {showChangePasswordForm && (
            <>
              <StyledInput
                status={status}
                type={'password'}
                showPassword={shouldShowPassword}
                setShouldShowPassword={() => setShouldShowPassword(!shouldShowPassword)}
                inputValue={newPassword}
                onChange={handleChangePassword}
                label="New password"
                descriptionText="Your password must be at least 8 characters."
                id="newPasswordInput"
              />

              <StyledInput
                status={status}
                showPassword={shouldShowPassword}
                inputValue={confirmPassword}
                onChange={handleChangeConfirmPassword}
                label="Confirm password"
                id="confirmPasswordInput"
              />
              {status === 'error' && newPassword.length > 0 && (
                <ErrorMessage>{errorMessage}</ErrorMessage>
              )}
            </>
          )}
        </FormContainer>

        <Buttons>
          <Button
            type="primary"
            onClick={handleSave}
            disabled={formHasNotChanged || (status === 'error' && showChangePasswordForm)}
          >
            Save Changes
          </Button>
          <Button
            onClick={handleCancel}
            disabled={!showChangePasswordForm && formHasNotChanged}
            style={{ width: '100px' }}
          >
            Cancel
          </Button>
        </Buttons>
      </Section>

      <Divider />

      <TimeZone />

      <Divider />

      <Section
        title="Delete your account"
        description="Once you delete your account, you will lose all data associated with it."
      >
        <DeleteButton onClick={() => setShouldShowModal(true)} style={{ marginLeft: '16px' }}>
          Delete
        </DeleteButton>
      </Section>
    </Container>
  );
};

export default Account;
