import { useEffect, useState } from 'react';
import { Button, Dropdown, MenuProps, Tooltip } from 'antd';
import { useToast } from '../../../../../../hooks/useToast';
import { coreAPI } from '../../../../../../API/core';
import { ChevronDown } from '../../../../../shared/components/Icons/ChevronDown';

interface Props {
  meetingType: string | null;
  eventId?: string;
  size?: 'small' | 'middle' | 'large';
  showAll?: boolean;
  onChange?: (type: string) => void;
  hasPermissions?: boolean;
}

export const MeetingTypeSelector: React.FC<Props> = ({
  meetingType: meetingTypeProps,
  eventId,
  size = 'small',
  showAll = false,
  onChange,
  hasPermissions = true,
}) => {
  const [meetingType, setMeetingType] = useState(meetingTypeProps);
  const [loading, setLoading] = useState(false);

  const { success, error: errorToast } = useToast();

  const meetingTypeDisplayName = meetingType || 'All Meeting Type';

  const items: MenuProps['items'] = [
    {
      label: 'Meeting Type',
      key: '0',
      disabled: true,
    },
    {
      label: 'Pre-Sales',
      key: 'Pre-Sales',
      children: [
        { label: 'Discovery', key: 'Discovery' },
        { label: 'Qualifying', key: 'Qualifying' },
        { label: 'Closing', key: 'Closing' },
      ],
    },
    {
      label: 'Post-Sales',
      key: 'Post-Sales',
      children: [
        {
          label: 'Onboarding + Implementation',
          key: 'Onboarding + Implementation',
        },
        { label: 'Progress Sync', key: 'Progress Sync' },
        { label: 'Issue or Escalation', key: 'Issue or Escalation' },
        { label: 'Strategic Review', key: 'Strategic Review' },
        { label: 'Renewal Prep', key: 'Renewal Prep' },
      ],
    },
    {
      label: 'Internal',
      key: 'Internal',
      children: [
        {
          label: '1:1',
          key: '1:1',
        },
        { label: 'Account Related', key: 'Account Related' },
        { label: 'Team', key: 'Team' },
        { label: 'Cross Functional', key: 'Cross Functional' },
      ],
    },
    {
      label: 'External',
      key: 'External',
      children: [
        { label: 'Consultant', key: 'Consultant' },
        { label: 'Vendor', key: 'Vendor' },
        { label: 'User Research', key: 'User Research' },
      ],
    },
    {
      label: 'Other',
      key: 'Other',
    },
  ].map(o => {
    if (!o.children || o.children.length === 0) {
      return {
        ...o,
        onClick: () => setMeetingType(o.key),
      };
    } else {
      return {
        ...o,
        children: o.children?.map(c => ({
          ...c,
          onClick: () => setMeetingType([o.key, c.key].join(' / ')),
        })),
      };
    }
  });

  if (showAll) {
    items.splice(0, 1, {
      label: 'All Meeting Type',
      key: 'All Meeting Type',
      onClick: () => setMeetingType('All Meeting Type'),
    });
  }

  const handleMeetingTypeChanged = (type: string) => {
    setMeetingType(type);

    if (onChange) {
      onChange(type);
      return;
    }

    if (!eventId) return;

    setLoading(true);
    const coreApi = new coreAPI();
    coreApi
      .updateEvent({
        id: eventId,
        type,
      })
      .then(() => success(`Meeting type changed successfully`))
      .catch(() => errorToast(`There was an error changing the Meeting Type`))
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    if (meetingType !== meetingTypeProps) handleMeetingTypeChanged(meetingType || '');
  }, [meetingType]);

  useEffect(() => {
    setMeetingType(meetingTypeProps);
  }, [meetingTypeProps]);

  return (
    <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
      <Dropdown menu={{ items }} disabled={loading || !hasPermissions}>
        <Button
          disabled={loading}
          loading={loading}
          size={size}
          style={{
            display: 'flex',
            alignItems: 'center',
            width: '180px',
            justifyContent: 'space-between',
          }}
        >
          <span style={{ display: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
            <Tooltip title={meetingTypeDisplayName} placement="top">
              {meetingTypeDisplayName}
            </Tooltip>
          </span>
          <ChevronDown />
        </Button>
      </Dropdown>
    </div>
  );
};
