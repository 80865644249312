import { Select } from 'antd';
import { useAccountSearch } from '../../../../features/account/account-search/account-search.hook';
import { Account } from '../../../../features/account/account-search/types';
import Styles from './styles';
import { AccountIcon } from '../../../../features/shared/components/AccountIcon';
import { useEffect, useMemo, useState } from 'react';

const { DropdownOption } = Styles;
const { Option } = Select;

interface Props {
  onSelect: (accountId: string) => unknown;
  value?: Account;
}

const search = (value: string, option?: string) => {
  if (value === '' || !option) return true;
  return option.toLowerCase().includes(value.toLowerCase());
};

const optionBuilder = (a: Account) => {
  return (
    <Option key={a.id} $value={a.name}>
      <DropdownOption>
        <AccountIcon provider={a.provider} />

        {a.name}
      </DropdownOption>
    </Option>
  );
};

export const AccountSelector: React.FC<Props> = ({ onSelect, value }) => {
  const { isFetching, accounts } = useAccountSearch();
  const [selectedAccount, setSelectedAccount] = useState<Account | null>(null);

  useEffect(() => {
    if (value) {
      setSelectedAccount(value);
    }
  }, [value]);

  const valueOption = useMemo(() => {
    if (selectedAccount) {
      return optionBuilder(selectedAccount);
    }
    return null;
  }, [selectedAccount]);

  const handleAccountSelected = (accountId: string) => {
    const account = accounts.find(acc => acc.id === accountId);
    if (account) {
      setSelectedAccount(account);
      onSelect(accountId);
    }
  };

  const accountsSorted = useMemo(() => {
    return accounts.sort((a, b) => {
      if (a.name < b.name) {
        return -1;
      }

      if (a.name > b.name) {
        return 1;
      }

      return 0;
    });
  }, [accounts]);

  return (
    <Select
      showSearch
      loading={isFetching}
      disabled={isFetching}
      value={valueOption}
      onSearch={value => search(value)}
      onChange={selected => handleAccountSelected(selected as unknown as string)}
      filterOption={(value, option) => {
        return search(value, option?.$value as string);
      }}
      style={{ width: '200px', alignSelf: 'flex-start' }}
    >
      {accountsSorted.map(f => {
        return optionBuilder(f);
      })}
    </Select>
  );
};
