import React from 'react';
import { PortfolioInsightsPage } from './page';
import { DashboardFilterPovider } from '../../features/shared/dashboard-filters/dashboard-filters.context';
import { useQuery } from '@tanstack/react-query';
import { coreService } from '../../services/core/core-service';
import { addDays } from 'date-fns';
import { Filter } from '../../services/types';
import { KeyToFilterStore, ListFilterStore } from '../../features/shared/dashboard-filters/types';

const DEFAULT_FILTER: Filter = {
  lifecycleStage: [],
  revenueSegment: [],
  industry: [],
  geography: [],
  primaryDealStage: [],
  accountIds: [],
  dealStage: [],
  renewalIn: -1,
  contractValueMin: 0,
  contractValueMax: 0,
  dateRange: [addDays(new Date(), -30), new Date()],
  dateRangeValue: '30d',
};

export const PortfolioInsights: React.FC = () => {
  const [filters, setFilters] = React.useState<Filter>(DEFAULT_FILTER);

  const handleActiveFilters = (f: Partial<KeyToFilterStore>) => {
    const updatedFilters = JSON.parse(JSON.stringify(filters));

    Object.keys(f).forEach(k => {
      {
        if (f[k] && f[k]?.type === 'list') {
          if (updatedFilters[k]) {
            const filter = updatedFilters[k] as ListFilterStore;

            filter.value.options = updatedFilters[k].value.options.map((filter: any) => {
              const updatedFilter = (f[k] as ListFilterStore).value.options.find(
                f => filter.id === f.id,
              );
              return { ...filter, selected: updatedFilter?.selected || true };
            });

            updatedFilters[k] = filter;
          }
        }
      }
    });

    // setFilters(updatedFilters);
  };

  const { data } = useQuery({
    queryKey: ['portfolio-insights-all-view'],
    queryFn: async () => {
      const data = await coreService.getAllInsightsEngine(
        addDays(new Date(), -30).toISOString(),
        new Date().toISOString(),
        'mentions',
        'mentions',
      );
      setFilters({ ...DEFAULT_FILTER, ...data?.filters });
      return data;
    },
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    refetchOnReconnect: false,
  });

  return (
    <DashboardFilterPovider
      filters={{
        dateRange: {
          type: 'dateRange',
          config: {
            value: filters.dateRangeValue || 'custom',
            interval: filters.dateRange,
          },
        },
        account: {
          type: 'account',
          config: {
            selected: filters.accountIds,
          },
        },
        geography: {
          type: 'list',
          componentProps: {
            style: { display: 'none' },
            label: 'Geography',
          },
          config: {
            allowNoValueSet: true,
            options: filters?.geography.map(g => ({ ...g, selected: true })),
          },
        },
        industry: {
          type: 'list',
          componentProps: {
            style: { display: 'none' },
            label: 'Industry',
          },
          config: {
            allowNoValueSet: true,
            options: filters?.industry.map(g => ({ ...g, selected: true })),
          },
        },
        revenueSegment: {
          type: 'list',
          componentProps: {
            style: { display: 'none' },
            label: 'Revenue Segment',
          },
          config: {
            allowNoValueSet: true,
            options: filters?.revenueSegment.map(g => ({ ...g, selected: true })),
          },
        },
        dealStage: {
          type: 'list',
          componentProps: {
            style: { display: 'none' },
            label: 'Deal Stage',
          },
          config: {
            allowNoValueSet: true,
            options: filters?.primaryDealStage.map(g => ({ ...g, selected: true })),
          },
        },
        lifecycleStage: {
          type: 'list',
          componentProps: {
            style: { display: 'none' },
            label: 'Lifecycle Stage',
          },
          config: {
            allowNoValueSet: true,
            options: filters?.lifecycleStage.map(g => ({ ...g, selected: true })),
          },
        },
        arr: {
          type: 'numberRange',
          componentProps: {
            style: { display: 'none' },
            label: 'ARR',
          },
          config: {
            interval: [filters.contractValueMin, filters.contractValueMax],
          },
        },
        daysUntilRenewal: {
          type: 'select',
          config: {
            defaultValue: -1,
            options: [
              {
                value: -1,
                label: 'All Days Until Renewal',
              },
              {
                value: 30,
                label: '30 Days Until Renewal',
              },
              {
                value: 60,
                label: '60 Days Until Renewal',
              },
              {
                value: 90,
                label: '90 Days Until Renewal',
              },
              {
                value: 120,
                label: '120 Days Until Renewal',
              },
            ],
          },
          componentProps: {
            label: 'Days Until Renewal',
            style: { width: 200, display: 'none' },
          },
        },
        meetingType: {
          type: 'meetingType',
          config: {
            defaultValue: null,
          },
        },
      }}
    >
      <PortfolioInsightsPage initialData={data || null} onStoreFilter={handleActiveFilters} />
    </DashboardFilterPovider>
  );
};
