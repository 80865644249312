import React from 'react';
import { CRMOrganizationSection } from './CRMOrganizationSection';
import { UpdateLoader } from '../../../../../../componentsV2/UpdateLoader';
import { PropertyMappingProvider } from '../../../../../../features/crm/property-mapping/components/PropertyMappingProvider';
import { FallbackAccount } from './FallbackAccount';
import Section from '../../../_Misc/Section';
import Styled from './style';
import { useCrmIntegration } from '../../../../../../features/crm-integration/hooks/use-crm-integration';

const { Container } = Styled;
interface Props {
  disableUserInteractions: boolean;
}

export const CRMSetupSection: React.FC<Props & { ref: React.Ref<HTMLDivElement> }> =
  React.forwardRef<HTMLDivElement, Props>((props, ref) => {
    const { isFetching: isFetchingCrmIntegration } = useCrmIntegration();

    const { disableUserInteractions } = props;

    return (
      <Section
        title={'CRM Setup'}
        description="Organize your CRM integration by mapping the most important account properties to UpdateAI."
        isBeta
        learnMore="https://help.update.ai/en/articles/7211777-how-to-integrate-salesforce-or-hubspot-crm-into-updateai"
      >
        <Container>
          {isFetchingCrmIntegration ? (
            <UpdateLoader min />
          ) : (
            <PropertyMappingProvider>
              <CRMOrganizationSection disableUserInteractions={disableUserInteractions} />
            </PropertyMappingProvider>
          )}
        </Container>
      </Section>
    );
  });
