import { AxiosInstance } from 'axios';
import { createAxiosInstance } from '../../utils/axios';
import {
  AskMeAnythingInsightArgs,
  AskMeAnythingRecapArgs,
  AskMeAnythingResult,
  FetchUserSettingsResult,
  CreateZapierApiKeyResult,
  GetInsightsAggregatedDataArgs,
  GetInsightsAggregatedDataResult,
  GetInsightsItemsArgs,
  GetInsightsItemsResult,
  GetOrganizationInfoResult,
  RequestIntegrationUrlResult,
  SummarizeItemsArgs,
  SummarizeItemsResult,
  FetchUserLibrariesApiResponse,
  CreateFolderApiResponse,
  GetFoldersItemsApiResponse,
  FetchFolderItemApiResponse,
  ChatItem,
  GetInsightCategoriesResponse,
  UpdateInsightCategoriesArgs,
  GetAccountsResponse,
  InviteBotArgs,
  UploadFileArgs,
  SummarizeInsightsArgs,
  SummarizeInsightsResult,
  ILink,
  GetCrmMappingResult,
  UpdateMappingArgs,
  FetchRecapResponse,
  UpdateEventArgs,
  GetEventReportResponse,
  AllInsightQueryData,
  TopAccountsType,
  TopMentionsType,
  TopTopicsType,
  InsightsFilters,
  Filter,
  GetAccountReportResponse,
  GetAccountEventSummarizationsResponse,
  SummarizeEventsResponse,
  GetAccountActionItemsArgs,
  GetAccountActionItemsResponse,
  GetAccountTrendsResponse,
  GetWorkspaceCrmIntegrationResponse,
  UpdateWorkspaceSelectedPropertiesArgs,
  GetAccountCrmPropertiesResponse,
} from '../types';
import { Settings } from '../../features/user/types';
import { User } from '../../redux/typings/session';
import { ContextData } from '../../pages/settings/Tabs/Organizations/components/WorkspaceContext';
import { StoredFilterType } from '../../pages/insights/components/StoredFilters/types';
import { StringGradients } from 'antd/es/progress/progress';

class CoreService {
  constructor(private readonly axiosInstance: AxiosInstance) {}

  async cropPlaybackCropping(recapId: string, crop: { start: number; end: number }) {
    await this.axiosInstance.put(`/events/${recapId}/playback/cropping`, crop);
  }

  async changeMemberRole(userId: string, role: string) {
    const response = await this.axiosInstance.put(`/users/${userId}/membership/role`, {
      value: role,
    });
    return response.data;
  }

  async removeMember(userId: string) {
    const response = await this.axiosInstance.delete(`/users/${userId}/membership`);
    return response.data;
  }

  async transferOrganization(userId: string) {
    await this.axiosInstance.post(`/organizations/transfer`, {
      toUserId: userId,
    });
  }

  async summarizeItems(args: SummarizeItemsArgs) {
    const response = await this.axiosInstance.post<SummarizeItemsResult>(
      `/insights/summarize`,
      null,
      {
        params: args,
      },
    );
    return response.data;
  }

  async getInsightsAggregatedData(args: GetInsightsAggregatedDataArgs) {
    const response = await this.axiosInstance.get<GetInsightsAggregatedDataResult>(
      `/insights/aggregation`,
      {
        params: args,
      },
    );
    return response.data;
  }

  async getInsightsItems(args: GetInsightsItemsArgs) {
    const response = await this.axiosInstance.get<GetInsightsItemsResult>(`/insights/items`, {
      params: args,
    });
    return response.data;
  }

  async getEventReport(eventId: string, reportId: string) {
    const response = await this.axiosInstance.get<GetEventReportResponse>(
      `/events/${eventId}/reports/${reportId}`,
    );
    return response.data;
  }

  async askMeAnythingInsight(args: AskMeAnythingInsightArgs) {
    const response = await this.axiosInstance.post<AskMeAnythingResult>(
      `/ask/insights`,
      { chat: args.chat },
      {
        params: args.filters,
      },
    );
    return response.data;
  }

  async askMeAnythingRecap(args: AskMeAnythingRecapArgs) {
    const response = await this.axiosInstance.post<AskMeAnythingResult>(`/ask/recap`, {
      chat: args.chat,
      recapId: args.recapId,
    });
    return response.data;
  }

  async askMeAnythingAccount(
    chat: ChatItem[],
    accountId: string,
    from: string | null,
    to: string | null,
  ) {
    const response = await this.axiosInstance.post<AskMeAnythingResult>(
      `/ask/accounts/${accountId}?from=${from}&to=${to}`,
      {
        chat: chat,
      },
    );
    return response.data;
  }

  async modifyMemberMeetingsVisibilityLock(userId: string, locked: boolean) {
    await this.axiosInstance.put(`/members/${userId}/share-settings/lock`, { locked });
  }

  async modifyMemberMeetingsVisibility(
    userId: string,
    args: {
      internalMeetingsVisibility: string;
      externalMeetingsVisibility: string;
    },
  ) {
    await this.axiosInstance.put(`/members/${userId}/meetings-visibility`, args);
  }

  async enableGlobalMeetingVisibility() {
    await this.axiosInstance.post(`/organizations/meeting-visibility/enable`);
  }

  async disableGlobalMeetingVisibility() {
    await this.axiosInstance.post(`/organizations/meeting-visibility/disable`);
  }

  async updateOrganizationDetails(payload: { id: string; name: string }) {
    await this.axiosInstance.patch(`/organizations/${payload.id}`, {
      name: payload.name,
    });
  }

  async modifyGlobalMeetingVisibility(args: {
    internalMeetingsVisibility: string;
    externalMeetingsVisibility: string;
  }) {
    await this.axiosInstance.put(`/organizations/meetings-visibility`, args);
  }

  async fetchOrganization(): Promise<GetOrganizationInfoResult> {
    const response = await this.axiosInstance.get<GetOrganizationInfoResult>(
      `/users/me/organization`,
    );
    return response.data;
  }

  async summarizeMeetings(accountId: string, eventIds: string[]): Promise<SummarizeEventsResponse> {
    const { data } = await this.axiosInstance.post<SummarizeEventsResponse>(
      `/accounts/${accountId}/summarize-meetings`,
      {
        eventIds,
      },
    );
    return data;
  }

  async updateDoNotRecordingList(date: string[]): Promise<void> {
    const response = await this.axiosInstance.put(`/users/me/organization/do-not-record-list`, {
      value: date,
    });
    return response.data;
  }

  async toggleAllowJoinByDomain(organizationId: string, value: boolean): Promise<void> {
    await this.axiosInstance.put(`/organizations/${organizationId}/allow-join-by-domain`, {
      value,
    });
  }

  async inviteUsers(emails: string[]) {
    const requests = emails.map((email: string) =>
      this.axiosInstance.post('/invitations', { email: email, role: 'MEMBER' }),
    );
    try {
      const responses = await Promise.all(requests);
      return responses.map(response => response.data);
    } catch (error) {
      console.error('Failed API calls:', error);
      throw error;
    }
  }

  async cancelInvitation(invitationId: string) {
    await this.axiosInstance.post(`/invitations/${invitationId}/cancel`);
  }

  async resendInvitation(invitationId: string) {
    await this.axiosInstance.post(`/invitations/${invitationId}/resend`);
  }

  async changeMemberType(userId: string, membershipType: string) {
    await this.axiosInstance.put(`/users/${userId}/membership/type`, {
      value: membershipType,
    });
  }

  async updateSettings(settings: Partial<Settings>) {
    await this.axiosInstance.patch(`/users/me/settings`, { ...settings });
  }

  async updateEvent(eventId: string, args: UpdateEventArgs) {
    await this.axiosInstance.patch(`/events/${eventId}`, args);
  }

  async fetchSettings() {
    const response = await this.axiosInstance.get<FetchUserSettingsResult>(`/users/me/settings`);
    return response.data;
  }

  async createZapierApiKey() {
    const { data } = await this.axiosInstance.post<CreateZapierApiKeyResult>(`/zapier/api-keys`);
    return data;
  }

  async removeIntegration(integrationId: string) {
    await this.axiosInstance.delete(`/users/me/integrations/${integrationId}`);
  }

  async removeWorkspaceIntegration(integrationId: string) {
    await this.axiosInstance.delete(`/organizations/integrations/${integrationId}/disconnect`);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async requestIntegrationUrl(type: string, params?: any) {
    const { data } = await this.axiosInstance.get<RequestIntegrationUrlResult>(
      `/users/me/integrate/${type}`,
      { params },
    );
    return data.url;
  }

  async refreshCrmIntegration(userId: string) {
    const response = await this.axiosInstance.post(`users/${userId}/integrations/sync`);
    return response.data;
  }

  async fetchUserLibraries() {
    const { data } = await this.axiosInstance.get<FetchUserLibrariesApiResponse>(
      `/library/libraries`,
    );
    return data;
  }

  async createFolder(libraryId: string, folderName: string) {
    const { data } = await this.axiosInstance.post<CreateFolderApiResponse>(
      `/library/libraries/${libraryId}/folders`,
      { folderName },
    );
    return data;
  }

  async deleteFolder(folderId: string) {
    await this.axiosInstance.delete(`/library/folders/${folderId}`);
  }

  async renameFolder(folderId: string, folderName: string) {
    await this.axiosInstance.patch(`/library/folders/${folderId}`, { folderName });
  }

  async fetchFolderItems(folderId: string) {
    const { data } = await this.axiosInstance.get<GetFoldersItemsApiResponse>(
      `/library/folders/${folderId}/items`,
    );
    return data;
  }

  async removeFolderItem(folderId: string, itemId: string) {
    await this.axiosInstance.delete(`/library/folders/${folderId}/items/${itemId}`);
  }

  async addFolderItem(folderId: string, itemId: string) {
    await this.axiosInstance.post(`/library/folders/${folderId}/items/${itemId}`);
  }

  async fetchFolderItem(itemId: string) {
    const { data } = await this.axiosInstance.get<FetchFolderItemApiResponse>(
      `/folder-items/${itemId}`,
    );
    return data;
  }

  async getMicrosoftSignupUrl() {
    const params = new URLSearchParams();
    const locationParams = new URLSearchParams(window.location.search);

    const utmSource = locationParams.get('utm_source') || '';
    const utmMedium = locationParams.get('utm_medium') || '';
    const utmCampaign = locationParams.get('utm_campaign') || '';
    const utmId = locationParams.get('utm_id') || '';
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    params.append('utm_source', utmSource);
    params.append('timezone', timezone);
    params.append('utm_medium', utmMedium);
    params.append('utm_campaign', utmCampaign);
    params.append('utm_id', utmId);

    const response = await this.axiosInstance.get(`users/signup/microsoft?${params.toString()}`, {
      maxRedirects: 0,
    });
    return response.data;
  }

  async addItemsToFolder(folderId: string, itemIds: string[]) {
    await this.axiosInstance.post(`/folders/${folderId}/items`, { insightIds: itemIds });
  }

  async updateOrganizationCRMSettings(
    organizationId: string,
    newSettings: {
      pushToCrmWithPrimaryProfile: boolean;
    },
  ) {
    const { data } = await this.axiosInstance.post(
      `/organizations/${organizationId}/settings/crm`,
      {
        ...newSettings,
      },
    );

    return data;
  }

  async getInsightCategories(type: string) {
    const { data } = await this.axiosInstance.get<GetInsightCategoriesResponse>(
      `/users/me/workspace/insights/categories?type=${type}`,
    );
    return data;
  }

  async updateInsightCategories(args: UpdateInsightCategoriesArgs) {
    await this.axiosInstance.put(`/users/me/workspace/insights/categories`, args);
  }

  async generateAccountStory(accountId: string) {
    await this.axiosInstance.post(`/accounts/${accountId}/account-story/generate`);
  }

  async generateStakeholderProfile(contactId: string) {
    await this.axiosInstance.post(`/contacts/${contactId}/profile/generate`);
  }

  async getAccounts() {
    const { data } = await this.axiosInstance.get<GetAccountsResponse>(`/accounts`);
    return data;
  }

  async disconnectPrimaryProfile(organizationId: string) {
    await this.axiosInstance.delete(`/organizations/${organizationId}/crm/primary-profile`);
  }

  async inviteBot(args: InviteBotArgs) {
    await this.axiosInstance.post(`/bot/invite`, args);
  }

  async uploadRecording(args: UploadFileArgs, onProgress: (progress: number) => unknown) {
    const formData = new FormData();
    formData.append('file', args.file);
    formData.append('duration', args.duration);
    formData.append('participantsType', args.participantsType);

    if (args.title) {
      formData.append('title', args.title);
    }

    await this.axiosInstance.post(`/upload`, formData, {
      onUploadProgress: progressEvent => {
        const loaded = Math.round((progressEvent.loaded / progressEvent.total) * 100);
        onProgress(loaded);
      },
    });
  }

  async getContact(id: string) {
    const { data } = await this.axiosInstance.get(`/contacts/${id}`);
    return data;
  }

  async getShareLink(id: string) {
    const { data } = await this.axiosInstance.get(`/share-link/${id}`);
    return data;
  }

  async cancelShareLink(id: string) {
    await this.axiosInstance.delete(`/share-link/${id}`);
  }

  async cancelAllShareLinks(id: string) {
    await this.axiosInstance.delete(`/share-link/event/${id}`);
  }

  async validateShareLink(args: { shareLinkId: string; email: string }) {
    await this.axiosInstance.post('/share-link/validate', args);
  }

  async createShareLink(args: {
    eventId: string;
    description: string | null;
    authorizationMechanism: string;
    expiresAt: string;
    authorizedEmails: string[];
  }) {
    return await this.axiosInstance.post('/share-link', args);
  }

  async getAllShareLinks(eventId: string): Promise<{ links: ILink[] }> {
    const { data } = await this.axiosInstance.get(`/share-link/event/${eventId}`);
    data.links.sort((a: ILink, b: ILink) => {
      return new Date(a.expirationDate).getTime() - new Date(b.expirationDate).getTime();
    });

    return data;
  }

  async updateShareLink(id: string, args: { description?: string; expirationDate?: number }) {
    await this.axiosInstance.patch(`/share-link/${id}`, {
      description: args.description !== null ? args.description : undefined,
      expirationDate: args.expirationDate !== null ? args.expirationDate : undefined,
    });
  }

  async summarizeInsights(args: SummarizeInsightsArgs) {
    const { data } = await this.axiosInstance.post<SummarizeInsightsResult>(
      `/insights/summary`,
      args,
    );
    return data;
  }

  async generateMeddicc(recapId: string) {
    return await this.axiosInstance.post(`/events/${recapId}/request-report`, {
      reportId: 'meddicc',
    });
  }

  async updateMeddicc(
    recapId: string,
    section: {
      id: string;
      value: string;
    },
  ) {
    return await this.axiosInstance.patch(`/events/${recapId}/reports/meddicc`, {
      sections: [section],
    });
  }

  async updateUser(payload: Partial<User>) {
    const response = await this.axiosInstance.post(`/users/me/update`, payload);
    return response.data;
  }

  async fetchCheatSheet() {
    const response = await this.axiosInstance.get(`/users/me/client-cheatsheet/config`);
    return response.data;
  }

  async updateCheatSheet(body: { send: boolean; scheduleHour: number }) {
    await this.axiosInstance.put(`/users/me/client-cheatsheet/config`, body);
  }

  async fetchFavourites() {
    const response = await this.axiosInstance.get(`/cohort`);
    return response.data;
  }

  async addToFavouriteCohort(accountId: string) {
    await this.axiosInstance.post(`/cohort`, { account: accountId });
  }

  async removeFromFavouriteCohort(accountId: string) {
    await this.axiosInstance.delete(`/cohort/${accountId}`);
  }

  async getCrmMapping(workspaceId: string) {
    const { data } = await this.axiosInstance.get<GetCrmMappingResult>(
      `/workspaces/${workspaceId}/crm-properties`,
    );
    return data;
  }

  async updateCrmMapping(args: UpdateMappingArgs) {
    const { workspaceId, mapping } = args;
    const { data } = await this.axiosInstance.put<GetCrmMappingResult>(
      `/workspaces/${workspaceId}/properties/mapping`,
      { mapping },
    );
    return data;
  }

  async changePrimaryDeal(accountId: string, primaryDealId: string) {
    await this.axiosInstance.put<GetCrmMappingResult>(`/accounts/${accountId}/primary-deal`, {
      primaryDealId,
    });
  }

  async updateMeetingType(eventId: string, type: string) {
    await this.axiosInstance.put(`/events/${eventId}/type`, {
      value: type,
    });
  }

  async updateWorkspaceVisibility(eventId: string, visibility: string) {
    await this.axiosInstance.put(`/events/${eventId}/organization-visibility`, {
      value: visibility,
    });
  }

  async updatePublicVisibility(eventId: string, visibility: { [k: string]: boolean }) {
    await this.axiosInstance.put(`/events/${eventId}/public-visibility-config`, visibility);
  }

  async fetchRecap(recapId: string, secret: string) {
    const recap = await this.axiosInstance.get<FetchRecapResponse>(`/events/${recapId}`, {
      params: {
        secret,
      },
    });
    return recap.data;
  }

  async excludeRecapFromInsights(recapId: string) {
    await this.axiosInstance.post(`/recaps/${recapId}/insights/exclude`);
  }

  async excludeInsight(insightId: string) {
    await this.axiosInstance.post(`/insights/${insightId}/exclude`);
  }

  async includeRecapFromInsights(recapId: string) {
    await this.axiosInstance.post(`/recaps/${recapId}/insights/include`);
  }

  async includeInsight(insightId: string) {
    await this.axiosInstance.post(`/insights/${insightId}/include`);
  }

  async updateCompanyContext(data: ContextData) {
    await this.axiosInstance.post(`/workspace/context`, data);
  }

  async getCompanyContext() {
    return (await this.axiosInstance.get('/workspace/context')).data;
  }

  async getSsoSignupUrl(email: string) {
    const { data } = await this.axiosInstance.post(`/auth/sso/signup/url`, { email });
    return data;
  }

  async getSsoSigninUrl(email: string) {
    const { data } = await this.axiosInstance.post(`/auth/sso/signin/url`, { email });
    return data;
  }

  async getInsightsFilters() {
    return this.axiosInstance.get(`/accounts/filters`);
  }

  async createInsightsFilter(args: Omit<StoredFilterType, 'id'>) {
    return this.axiosInstance.post(`/accounts/filters`, { ...args });
  }

  async removeInsightsFilter(id: string) {
    return this.axiosInstance.delete(`/accounts/filters/${id}`);
  }

  async updateInsightsFilter(
    id: string,
    body: {
      name?: string;
      config?: Partial<Filter>;
    },
  ) {
    return this.axiosInstance.put(`/accounts/filters/${id}`, { ...body });
  }

  async getGongCredentials(): Promise<string> {
    return (await this.axiosInstance.get('/gong/credentials')).data;
  }

  async getGongMeetings(cursor?: string) {
    return (
      await this.axiosInstance.get('/gong/meetings', {
        params: {
          cursor,
        },
      })
    ).data;
  }

  async removeGongCredentials() {
    await this.axiosInstance.delete('/gong/credentials');
  }

  async addGongCredentials(id: string, key: string) {
    await this.axiosInstance.post('/gong/credentials', {
      tokenId: id,
      token: key,
    });
  }

  async importGongCalls(ids: string[]) {
    await this.axiosInstance.post('/gong/meetings/import', {
      ids,
    });
  }

  async getAllInsightsEngine(
    from: string,
    to: string,
    accountsSortBy: string,
    topicsSortBy: string,
    args?: Partial<InsightsFilters>,
  ): Promise<AllInsightQueryData> {
    const { data } = await this.axiosInstance.post(`/insights-engine/all/query`, {
      from: from,
      to: to,
      topTopics: {
        orderBy: topicsSortBy,
        limit: 10,
      },
      topTopicsByCategory: {
        orderBy: topicsSortBy,
        limit: 10,
      },
      topAccounts: {
        orderBy: accountsSortBy,
        limit: 10,
      },
      ...args,
    });

    const f: { [k: string]: { id: string; label: string }[] } = {};
    Object.entries(data.filters).forEach(([key, value]) => {
      const k = key as string;
      f[k] = (value as string[]).map((g: string) => ({ id: g.replace(' ', ''), label: g }));
    });
    data.filters = f;

    return data;
  }

  async getTopAccounts(
    from: string,
    to: string,
    page: number,
    size: number,
    orderBy: string,
    args?: Partial<InsightsFilters>,
  ): Promise<TopAccountsType> {
    const { data } = await this.axiosInstance.post(`/insights-engine/top-accounts/query`, {
      from: from,
      to: to,
      page: page,
      orderBy: orderBy,
      limit: size,
      ...args,
    });

    return data;
  }

  async getTopTopics(
    from: string,
    to: string,
    page: number,
    size: number,
    orderBy: string,
    args?: Partial<InsightsFilters & { insightType: string; meetingType?: string }>,
  ): Promise<TopTopicsType> {
    const { data } = await this.axiosInstance.post(`/insights-engine/top-topics/query`, {
      from: from,
      to: to,
      page: page,
      orderBy: orderBy,
      limit: size,
      insightType: args?.insightType,
      meetingType: args?.meetingType,
      ...args,
    });

    return data;
  }

  async getTopAccountsByTopicId(
    topicId: string,
    from: string,
    to: string,
    page: number,
    size: number,
    orderBy: string,
    args?: Partial<InsightsFilters>,
  ): Promise<TopAccountsType> {
    const { data } = await this.axiosInstance.post(`/insights-engine/top-accounts/query`, {
      topicId: topicId,
      from: from,
      to: to,
      page: page,
      orderBy: orderBy,
      limit: size,
      ...args,
    });

    return data;
  }

  async getTopMentionsByTopicId(
    topicId: string,
    from: string,
    to: string,
    page: number,
    size: number,
    orderBy: string,
    args?: Partial<InsightsFilters>,
  ): Promise<TopMentionsType> {
    const { data } = await this.axiosInstance.post(`/insights-engine/mentions/query`, {
      topicId: topicId,
      from: from,
      to: to,
      page: page,
      orderBy: orderBy,
      limit: size,
      ...args,
    });

    return data;
  }

  async updateJobTitle(value: { userId: string; jobTitle: string }) {
    await this.axiosInstance.put('/workspace/job-title', value);
  }

  async showActionItem(recapId: string, actionItemId: string) {
    await this.axiosInstance.post(`/events/${recapId}/action-items/${actionItemId}/show`);
  }

  async hideActionItem(recapId: string, actionItemId: string) {
    await this.axiosInstance.post(`/events/${recapId}/action-items/${actionItemId}/hide`);
  }

  async getAccountReport(accountId: string, reportId: string) {
    const { data } = await this.axiosInstance.get<GetAccountReportResponse>(
      `/accounts/${accountId}/reports/${reportId}`,
    );
    return data;
  }

  async generateAccountReport(accountId: string, reportId: string) {
    return await this.axiosInstance.post(`/accounts/${accountId}/request-report`, {
      reportId,
    });
  }

  async updateAccountReport(
    accountId: string,
    reportId: string,
    section: {
      id: string;
      value: string;
    },
  ) {
    return await this.axiosInstance.patch(`/accounts/${accountId}/reports/${reportId}`, {
      sections: [section],
    });
  }

  async getAccountEventSummarizations(accountId: string) {
    const { data } = await this.axiosInstance.get<GetAccountEventSummarizationsResponse>(
      `/accounts/${accountId}/event-summarizations`,
    );
    return data;
  }

  async getAccountActionItems(
    args: GetAccountActionItemsArgs,
  ): Promise<GetAccountActionItemsResponse> {
    const queryParams = new URLSearchParams();

    if (args.search) {
      queryParams.set('search', args.search);
    }

    queryParams.set('from', args.from.toISOString());
    queryParams.set('to', args.to.toISOString());
    queryParams.set('includeCompleted', String(args.includeCompleted));

    const { data } = await this.axiosInstance.get<GetAccountActionItemsResponse>(
      `/accounts/${args.accountId}/action-items?${queryParams.toString()}`,
    );
    return data;
  }

  async getAccountTrends(accountId: string) {
    const { data } = await this.axiosInstance.get<GetAccountTrendsResponse>(
      `/accounts/${accountId}/trends`,
    );
    return data;
  }

  async updateFallbackAccount(value: boolean, workspaceId: string) {
    return await this.axiosInstance.put(`/workspaces/${workspaceId}/allow-fallback-accounts`, {
      value: value,
    });
  }

  async getOrganization() {
    const response = await this.axiosInstance.get(`/users/me/organization`);
    return response.data;
  }
  async getWorkspaceParagonToken() {
    const { data } = await this.axiosInstance.get<{
      paragon: null | { projectId: string; token: string };
    }>(`/users/me/workspace/paragon`);
    return data;
  }

  async removeCrmIntegration() {
    await this.axiosInstance.delete(`/users/me/workspace/crm-integration`);
  }

  async getWorkspaceCrmIntegration() {
    const { data } = await this.axiosInstance.get<GetWorkspaceCrmIntegrationResponse>(
      `/users/me/workspace/crm-integration`,
    );
    return data;
  }

  async updateWorkspaceCrmIntegrationSelectedProperties(
    args: UpdateWorkspaceSelectedPropertiesArgs,
  ) {
    await this.axiosInstance.put(`/users/me/workspace/crm-integration/selected-properties`, {
      value: args.value,
    });
  }

  async getCrmAccountProperties(accountCrmId: string) {
    const { data } = await this.axiosInstance.get<GetAccountCrmPropertiesResponse>(
      `/users/me/workspace/crm-integration/accounts/${accountCrmId}`,
    );
    return data;
  }
}

export const coreHost = process.env.REACT_APP_API_CORE || 'http://localhost:3001';

export const coreService = new CoreService(createAxiosInstance(coreHost));
