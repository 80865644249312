import styled from 'styled-components';
import { colors } from '../../../../../../theme/colors';

const NotMappedText = styled.div`
  display: flex;
  align-items: center;
  color: #ff6b54;
  font-family: 'Cera Pro';
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  /* line-height: 24px; 200 */
  margin: 0;
  text-decoration-line: underline;
  cursor: pointer;
  height: 100%;
`;

const CrmPropertyValue = styled.span`
  color: ${colors.primary[500]};
  font-family: 'Cera Pro';
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 200% */
  max-width: 200px;
  height: 24px;
  overflow-y: auto;
`;

export default {
  NotMappedText,
  CrmPropertyValue,
};
